import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { Script } from 'gatsby';

import MenuRastreamentoSection from '@/components/Pages/Index/MenuRastreamentoSection';
import HomeSlider from '@/components/Pages/Index/HomeSlider';
import ServicesSection from '@/components/Pages/Index/ServicesSection';
import AGenerosoSection from '@/components/Pages/Index/AGenerosoSection';
import CotacaoColetaSection from '@/components/Pages/Index/CotacaoColetaSection';
import CompanyNumbersSection from '@/components/Pages/Index/CompanyNumbersSection';
import TrabalheConoscoSection from '@/components/Pages/Index/TrabalheConoscoSection';

import { PageWrapper, PageContainer, SectionWrapper } from '../styles/pages';
import { CMS_URL } from '@/config';

const IndexPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "index" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Início"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
   
      <Script type="text/javascript">
        {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
            j.async=true; j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PMCB7769');
        `}
      </Script>
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <MenuRastreamentoSection />
            </SectionWrapper>

            <SectionWrapper>
              <HomeSlider />
            </SectionWrapper>

            <SectionWrapper>
              <ServicesSection />
            </SectionWrapper>

            <SectionWrapper>
              <AGenerosoSection />
            </SectionWrapper>

            <SectionWrapper>
              <CotacaoColetaSection />
            </SectionWrapper>

            <SectionWrapper>
              <CompanyNumbersSection />
            </SectionWrapper>

            <SectionWrapper>
              <TrabalheConoscoSection />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default IndexPage;
